@font-face {
  font-family: 'Daimler CS';
  src: url('./assets/fonts/daimlercs-bold-webfont.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Daimler CS';
  src: url('./assets/fonts/daimlercs-demi-webfont.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Daimler CS';
  src: url('./assets/fonts/daimlercs-demiitalic-webfont.woff2') format('woff2');
  font-style: italic;
  font-weight: 400;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Daimler CS', sans-serif;
    font-size: 1vw;
    width: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    color: #333;
}

body.app--in-iframe {
    min-height: initial;
}

body.app--in-iframe #root {
    height: 57vW;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
}

svg, img {
    display: block;
    width: 100%;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

::-webkit-scrollbar-thumb{
    background: #7bb5cf;
    border-radius: 5px;
}
::-webkit-scrollbar-track {
    background: none;
}
::-webkit-scrollbar-button{
    background: none;
}
